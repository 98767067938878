import React, { ReactElement } from "react"
const styles = require("./index.module.css")

function PrivacyContainer(): ReactElement {
  return (
    <div className={`max-w-1366 mx-auto ${styles.wrapper}`}>
      <div className="my-8 text-center">
        <h3 className="font-bold text-2xl">General Privacy Policy</h3>
      </div>
      <p>
        <a href="#agb1">
          <strong>1. Responsible Authority</strong>
        </a>
      </p>
      <p>
        <a href="#agb2">
          <strong>2. Purpose of Data Processing</strong>
        </a>
      </p>
      <p>
        <a href="#agb3">
          <strong>3. Saved Data</strong>
        </a>
      </p>
      <p>
        <a href="#agb4">
          <strong>4. Recipient or Categories of Recipients</strong>
        </a>
      </p>
      <p>
        <a href="#agb5">
          <strong>5. Data Security</strong>
        </a>
      </p>
      <p>
        <a href="#agb6">
          <strong>6. Enforcement of Rights</strong>
        </a>
      </p>
      <p>
        <a href="#agb7">
          <strong>7. Right of Appeal at Supervisory Authorities</strong>
        </a>
      </p>
      <p>
        <a href="#agb8">
          <strong>8. Use of Cookies</strong>
        </a>
      </p>
      <p>
        <a href="#agb9">
          <strong>9. Google Analytics</strong>
        </a>
      </p>
      <p>
        <a href="#agb10">
          <strong>10. Newsletter</strong>
        </a>
      </p>
      <p>
        <a href="#agb11">
          <strong>11. Other Communications</strong>
        </a>
      </p>
      <p>
        <a href="#agb12">
          <strong>12. Links</strong>
        </a>
      </p>
      <p>
        <a href="#agb13">
          <strong>13. Changes in Data Security</strong>
        </a>
      </p>
      <p>
        <a href="#agb14">
          <strong>14. Miscellaneous</strong>
        </a>
      </p>
      <p>
        Rent-O-Ride regards the protection of your personal data our major
        responsibility. Below we would like to inform you about the data we
        process and its purpose in accordance with the relevant data protection
        laws.
      </p>
      <p id="agb1">
        <br />
        <br />
        <strong>1. Responsible Authority</strong>
      </p>
      <p>
        The responsible authority for data processing in Europe is:
        <br />
        <br />
        Rent-O-Ride Deutschland GmbH
        <br />
        Landsbergerstrasse 155
        <br />
        80687 Munich
        <br />
        Germany
        <br />
        <br />
        If you have questions or comments about our data protection privacy
        policy in general or the assertion of your rights (see No. 6), you can
        contact us at{" "}
        <a href="mailto:data-protection@rent-o-ride.com">
          data-protection@rent-o-ride.com
        </a>{" "}
        or contact our data protection officer in writing by post:
        <br />
        <br />
        Ms Kirsten Waidmann
        <br />
        Rent-O-Ride Deutschland GmbH
        <br />
        Landsbergerstrasse 155
        <br />
        80687 Munich
        <br />
        Germany
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb2">
        <br />
        <br />
        <strong>2. Purpose of Data Processing</strong>
      </p>
      <p>We collect and process data for the following reasons:</p>
      <ul>
        <li>To ensure the functionality and performance of the website.</li>
        <li>
          To process the booking of rental cars, their delivery and payment
          (contract processing).
        </li>
        <li>
          To share interesting offers and news through our newsletter assuming
          you as a client have agreed to receive these offers.
        </li>
      </ul>
      [<a href="#top">Back to top</a>]<p></p>
      <p id="agb3">
        <br />
        <br />
        <strong>3. Saved Data</strong>
      </p>
      <p>
        Basically, it is possible to visit our website without providing any
        personal data.
        <br />
        <br />
        For better performance and to guarantee our website’s functionality, we
        process the data transmitted to us by your Internet browser. These
        include the date and time of your visit, browser settings, the operating
        system and the IP address.
        <br />
        <br />
        To process a rental car booking, we need your name, address, age of the
        driver, contact information such as an email address or a phone number,
        credit card or bank information. In order to be able to guarantee
        certain offers, we may need your date of birth, driving licence and ID
        information.
        <br />
        <br />
        To send you information and offers via our newsletter, we require your
        email address.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb4">
        <br />
        <br />
        <strong>4. Recipients or Categories of Recipients</strong>
      </p>
      <p>
        Car rental bookings are handled by our mother company Rent-O-Ride LLC, a
        US-based company headquartered in Portland, ME 04103, USA. The data
        which you provide in a form on our website, such as those described in
        the Section 3, will be transmitted to Rent-O-Ride LLC, our payment
        service provider including Fraud Prevention, and your chosen car rental
        company, with the purpose of processing your car rental booking.
        <br />
        <br />
        We also reserve the right to transfer your data to debt collection
        agencies and business protection organizations (such as credit
        investigation companies).
        <br />
        <br />
        It is possible that the recipients of the data may be located in
        countries outside the European Economic Area (third countries) where the
        applicable law does not offer a comparable level of data protection. In
        such cases, Rent-O-Ride ensures that an adequate level of data
        protection is guaranteed in other ways.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb5">
        <br />
        <br />
        <strong>5. Data Security</strong>
      </p>
      <p>
        To protect your privacy, all booking inquiries and related payment
        transactions that reach us through our website are processed with HTTPS
        secure encryption using state of the art encryption (SSL / TLS). You
        will know that your browser uses this secure transmission as it is
        indicated by the Internet address beginning with "https" and a small
        lock symbol (usually) displayed in the address bar of your browser
        window. When the lock is closed, it is a safe transfer. Some browsers
        show the address bar or part of it in green colour for this case.
        (Please note that these security settings are only available on pages
        where you can see and send your request or submit your credit card
        details.)
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb6">
        <br />
        <br />
        <strong>6. Enforcement of Rights</strong>
      </p>
      <p>
        You have the right to request information about the personal data that
        we hold about you and to request the correction, deletion or restriction
        of the processing of this data at any time. To assert these rights,
        please use the contact options listed under No. 1.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb7">
        <br />
        <br />
        <strong>7. Right of Appeal at Supervisory Authorities</strong>
      </p>
      <p>
        In addition, you have the right to lodge a complaint with the competent
        supervisory authority. The supervisory authority responsible for Auto
        Europe Deutschland GmbH is as follows
        <br />
        <br />
        Bayerische Landesamt für Datenschutzaufsicht
        <br />
        Promenade 27
        <br />
        91522 Ansbach
        <br />
        Germany
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb8">
        <br />
        <br />
        <strong>8. Use of Cookies</strong>
      </p>
      <p>
        Rent-O-Ride uses cookies on our website. Cookies are small text files
        that are sent from our web server to your browser during your visit to
        our website that are stored by this on your computer for later
        retrieval.
        <br />
        <br />
        The purpose of these cookies is to continue to recognize your computer
        during a visit to our website when switching from one of our websites to
        another of our websites and to be able to determine the end of your
        visit.
        <br />
        <br />
        We also use these technologies to collect usage and preference
        information about you over time and across different platforms. So do
        our selected business partners, including our advertising partners.
        These companies use certain technologies to deliver adverts and
        marketing messages to you after visiting our website. Of course, you can
        also view our website without cookies. Internet browsers are regularly
        set to accept cookies. You can deactivate the use of cookies at any time
        in the settings of your browser. Please use the help features of your
        internet browser to find out how to change these settings. Please note
        that some features of our website may not work if you disable the use of
        cookies.
        <br />
        <br />
        The collection or storage of personal data in cookies does not take
        place in this context by us. We also do not use techniques that combine
        information generated by cookies with user data.
        <br />
        <br />
        If you prefer to delete the Rent-O-Ride cookies, please click{" "}
        <a href="/cookiecutter.html">here</a>.<br />[
        <a href="#top">Back to top</a>]
      </p>
      <p id="agb9">
        <br />
        <br />
        <strong>9. Google Analytics</strong>
      </p>
      <p>
        Our website uses Google Analytics, a web analytics service provided by
        Google Inc. ("Google"). Cookies are stored on your computer, which
        allows an analysis of your use of the website.
        <br />
        <br />
        The information generated by the cookie about your use of this website
        is usually transmitted to a Google server in the USA and stored there.
        We use the service on our website with activated IP anonymity. This
        means that on our website, your IP address will be abbreviated
        beforehand by Google within the member states of the European Union or
        in other contracting states of the Agreement in the European Economic
        Area and therefore anonymous. Only in exceptional cases will the full IP
        address be transferred to a Google server in the USA and abbreviated
        there. On our behalf, Google will use this information to evaluate your
        use of the website, to compile reports on website activity and to
        provide other services related to the website activity and internet
        usage to us as website operators. For more information about Google´s
        use of data, please visit:{" "}
        <a
          href="https://policies.google.com/technologies/partner-sites?hl=en"
          rel="noopener"
          target="_blank"
        >
          https://policies.google.com/technologies/partner-sites
        </a>
        <br />
        <br />
        The IP address obtained from your browser and used by Google Analytics
        will not be merged with other Google data.
        <br />
        <br />
        You can prevent the storage of cookies in your browser in the settings
        of your browser software (see 8.). Please note that some features of our
        website may not work if you disable the use of cookies. Furthermore, it
        is possible to prevent the collection by Google Analytics by setting an
        opt-out cookie. Please click on the following link:
        <br />
        <a href="https://www.rent-o-ride.com/privacy/?ga_out=Y">
          https://www.rent-o-ride.com/privacy/?ga_out=Y
        </a>
        <br />
        <br />
        In addition, you may prevent the collection and use of the data
        generated by the cookie and related to your use of the website
        (including your IP address) as well as the processing of this data by
        Google by downloading and installing the browser plug-in available via
        the following link:{" "}
        <a href="http://tools.google.com/dlpage/gaoptout?hl=en" rel="noopener">
          http://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb10">
        <br />
        <br />
        <strong>10. Newsletter</strong>
      </p>
      <p>
        On our website, you are offered the chance to receive information about
        our offers via newsletter. We use the so-called double opt-in procedure
        for the registration. After you have given us your email address, we
        will send you a confirmation email. Only when you click on the
        confirmation link contained in the email, will your email address be
        added to our mailing list. This ensures that only the actual owner of
        the email address can order the newsletter. Data processing in the
        context of the newsletter is based on your consent.
        <br />
        <br />
        You have the right to revoke this consent at any time for future
        newsletter emails.
        <br />
        <br />
        You can unsubscribe <a href="/unsubscribe/">here</a> from the newsletter
        and thus revoke your consent.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb11">
        <br />
        <br />
        <strong>11. Other Communications</strong>
      </p>
      <p>
        Rent-O-Ride will also send you a "pre-departure email" prior to pick-up
        containing some useful advice and reminders of what you will need to
        take with you to the car rental counter. After you have completed your
        rental, we may send you a questionnaire or ask you to review your
        experience with us.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb12">
        <br />
        <br />
        <strong>12. Links</strong>
      </p>
      <p>
        Our website may contain links to other platforms. If you are redirected
        to another website by clicking on one of the links, the privacy policy
        of Rent-O-Ride Deutschland GmbH no longer applies. Instead, the policy
        of that respective website operator will apply.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb13">
        <br />
        <br />
        <strong>13. Changes in Data Security</strong>
      </p>
      <p>
        We reserve the right to change this privacy policy in order to adapt it
        to the new functions on the website or a changed legal situation. The
        current policy in force at the time of your visit to our website will be
        valid.
        <br />[<a href="#top">Back to top</a>]
      </p>
      <p id="agb14">
        <br />
        <br />
        <strong>14. Miscellaneous</strong>
      </p>
      <p>
        Rent-O-Ride Limited, Premier House, Office 1b, Burnley Road, Altham,
        Accrington BB5 5TX, mediates as a broker for renowned car rental
        companies worldwide for Rent-O-Ride LLC, 43 Northport Dr, Portland, ME
        04103, USA, registered as Limited Liability Company in the State of
        Delaware, USA with registration number 3726730 (hereinafter "Auto
        Europe"). For all consumers from the European Union, the conditions of
        this agreement and all objections related to the payment are subject to
        the law of the Federal Republic of Germany.
        <br />[<a href="#top">Back to top</a>]
      </p>
    </div>
  )
}

export default PrivacyContainer
